<!-- Container for custom date range input form -->
<div
  class="custom-datepicker-range-input-form"
  [attr.disabled]="disabled || null"
>
  <mat-form-field class="custom-datepicker-input-label">
    <mat-label class="custom-date-range-label">{{
      label | translate
    }}</mat-label>

    <mat-date-range-input
      class="custom-date-range-input"
      [required]="required"
      [formGroup]="controlContainer.control"
      [rangePicker]="picker"
      [disabled]="disabled"
      [min]="minDate"
      [max]="maxDate"
      [attr.testId]="testId"
    >
      <input
        matStartDate
        placeholder="{{ startDatePlaceholder | translate }}"
        (keydown)="checkDateRegex($event)"
        (blur)="onStartDateChange($event)"
        maxlength="10"
        formControlName="startDate"
      />
      <input
        matEndDate
        placeholder="{{ endDatePlaceholder | translate }}"
        (keydown)="checkDateRegex($event)"
        (blur)="onEndDateChange($event)"
        maxlength="10"
        formControlName="endDate"
      />
    </mat-date-range-input>
    <mat-hint>{{ hint | translate }}</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      (closed)="onDateRangeChange()"
      panelClass="custom-range-datepicker-panel-class"
    ></mat-date-range-picker>
    <mat-error
      *ngIf="
        (controlContainer.control?.get('startDate')?.errors &&
          (controlContainer.control?.get('startDate')?.errors[
            'matDatepickerMin'
          ] ||
            controlContainer?.control?.get('startDate')?.errors[
              'matDatepickerParse'
            ])) ||
        (controlContainer.control?.get('endDate')?.errors &&
          (controlContainer.control?.get('endDate')?.errors[
            'matDatepickerMin'
          ] ||
            controlContainer?.control?.get('endDate')?.errors[
              'matDatepickerParse'
            ]))
      "
    >
      <app-validation-message
        [errors]="{ matDatepickerParse: true }"
      ></app-validation-message>
    </mat-error>
  </mat-form-field>
</div>

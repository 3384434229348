

<div class="grid">
  <ng-container *ngFor="let widget of widgets; let i = index">
    <ng-container *ngIf="widget.visible == true">
      <div class="item" [attr.widget-index]="widget.position">
        <div class="item-content">
          <app-dashboard-widget
            [widget]="widget"
            (onRecreate)="onWidgetRecreate()"
            (onResize)="onWidgetResize()"
            (onRefresh)="onWidgetRefresh()"
          ></app-dashboard-widget>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

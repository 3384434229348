@if(!data?.requireReason && data.method === 'DELETE') {
  <app-dialog-delete 
  [(isAgreed)]="isAgreed" 
  [title]="data?.title" 
  [message]="data?.detail[this.selectedLanguage]?.checklist" 
  [label]="data?.detail[this.selectedLanguage]?.confirmationText"
  (confirm)="onConfirm()"
  (dismiss)="onDismiss()"
  [isRequireConfirmation]="data?.requireConfirmation"
  >
  
</app-dialog-delete>
}@else() {
  <div class="c4p-popup" style="max-width: 340px">
    <!-- gatekeeper title -->
    <div class="c4p-popup-title">
      <span class="c4p-horizontal-divider" translate>{{ data.title }}</span>
    </div>
    <div
      class="c4p-popup-content-top"
      [innerHTML]="data.detail[this.selectedLanguage].checklist"
    ></div>
    <!-- gate keeper reason -->
    <div *ngIf="data.requireReason" class="c4p-popup-content-top">
      <div class="c4p-popup-content-left-part">
        <img style="width: 27px;margin-top: 7px;" src="/assets/svg/false-icon.svg" alt="">
      </div>
      <div class="c4p-popup-content-right-part">
        <p class="c4p-font-large">
          <span translate>{{
            data.detail[this.selectedLanguage].description
          }}</span>
        </p>
        <div class="c4p-dimmed c4p-font-medium">
          <span translate>general.labels.MeaningfulChanges</span>
        </div>
        <mat-form-field appearance="fill">
          <textarea
            matInput
            class="c4p-form-text-area-gate-keeper"
            [(ngModel)]="reason"
            style="height: 150px"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  
    <!-- gatekeeper checklist -->
    <div *ngIf="data.requireConfirmation" class="c4p-popup-content-down">
      <app-form-input-checkbox
        [(ngModel)]="isAgreed"
        placeholder="{{ data.detail[this.selectedLanguage].confirmationText }}"
      ></app-form-input-checkbox>
    </div>
  
    <!-- gatekeeper buttons -->
    <div style="display: flex; overflow: hidden">
      <span class="c4p-button-cancel" (click)="onDismiss()" translate
        >general.labels.Cancel</span
      >
      <span class="c4p-button-ok" (click)="onConfirm()">
        <span
          *ngIf="
            (data.requireReason &&
              reason.length > 0 &&
              data.requireConfirmation &&
              isAgreed) ||
            (!data.requireReason && data.requireConfirmation && isAgreed) ||
            (data.requireReason && reason.length > 0 && !data.requireConfirmation)
          "
          translate
          >{{ data.title ? data.title : 'general.labels.Confirm' }}</span
        >
      </span>
    </div>
  </div>
}



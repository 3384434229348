import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewEncapsulation,
} from '@angular/core';
import {
  DATE_MONTH_YEAR_REGEX,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  YEAR_MONTH_FORMATS_ND,
} from '../../form-controls/form-controls.const';
import { AbstractControl, ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { CustomDateTimePipe } from '../../pipes';
import { DateUtils } from '../../utilities';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { DEFAULT_DATE_FORMAT} from '../../pipes/pipes.const';

@Component({
  selector: 'app-form-input-month-year-nd',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidationMessageComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app-form-input-month-year-nd.component.html',
  styleUrl: './app-form-input-month-year-nd.component.scss',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMATS_ND }],
})
export class AppFormInputMonthYearNdComponent implements OnInit {
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input({
    required: true,
    transform: (value: string) => {
      return 'app-form-input-date-' + value;
    },
  })
  testId: string;
  @Input() disabled: boolean = false;
  @Input() controlName: string;
  @Input() control: AbstractControl;

  @Output() datepickerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() datepickerClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDate: EventEmitter<void> = new EventEmitter<void>();

  dateMonthYearRegex = DATE_MONTH_YEAR_REGEX;
  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {
  }

  ngOnInit(): void {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.disabled) {
      this.control.disable();
    }
  }

  onDatepickerOpened(): void {
    this.datepickerOpened.emit();
  }

  onDatepickerClosed(): void {
    this.datepickerClosed.emit();
  }

  /** When date is picked from the date picker or manually entered
   */
  onChangeDate() {
    this.changeDate.emit();
  }

  /** When date is entered manually
   */
  valueChangeHandler(event$: Event) {
    const inputValue = (event$?.target as HTMLInputElement).value;
    const stringDate = '01/'+inputValue;
    if (!stringDate) return;
    if (!DateUtils.isValidDate(stringDate)) {
      this.control.setErrors({ matDatepickerParse: true });
    }else {
     const momentDate = DateUtils.format(stringDate, DEFAULT_DATE_FORMAT)
      this.control.setValue(momentDate)
    }
  }


  get minDateValue() {
    return this.minDate ?? DEFAULT_MIN_DATE;
  }

  get maxDateValue() {
    return this.maxDate ?? DEFAULT_MAX_DATE;
  }
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<any>) {
    const date = normalizedMonthAndYear;
    this.control.setValue(date)
    datepicker.close();
  }

}

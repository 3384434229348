import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, firstValueFrom, of, take } from 'rxjs';
import { TherapistModel } from '../../models';
import { CareProgramService } from '../../services/care-program.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelUtils } from '../../../../utilities';
import { DialogComponent } from '../../../../general-components';
import { MainTherapistDetailComponent } from './main-therapist-detail/main-therapist-detail.component';

@Component({
  selector: 'lib-main-therapist-section',
  templateUrl: './main-therapist-section.component.html',
  styleUrls: ['./main-therapist-section.component.css'],
})
export class MainTherapistSectionComponent implements OnInit {
  isCarefileCareProgram: boolean;
  showWarningMessage = false;
  errorMessage: string;

  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  get mainTherapistFormArray(): FormArray {
    return this.careprogramForm.get('therapists') as FormArray;
  }

  public displayedColumns = [
    'fullName',
    'employeeNumber',
    'startDate',
    'endDate',
    'operations',
  ];

  constructor(
    private careprogramService: CareProgramService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fillMainTherapists();
    this.formConfirmSubscription();
  }

  private async fillMainTherapists() {
    const therapists: TherapistModel[] = await this.getTherapists();
    if (this.mainTherapistFormArray.length === 0) {
      therapists.forEach((therapist) => this.addMainTherapist(therapist));
    }
  }

  private async getTherapists(): Promise<TherapistModel[]> {
    let foundTherapists: TherapistModel[] = [];
    const selectedTherapists =
      this.careprogramService.selectedCareProgram$?.value?.therapists;

    if (this.careprogramForm?.value?.id) {
      const found = await firstValueFrom(
        this.careprogramService
          .getCareProgram(this.careprogramForm.value.id, 'Carefile', 'Carefile')
          .pipe(take(1)),
      );
      foundTherapists = found?.therapists;
    }
    if (foundTherapists?.length > 0) {
      return foundTherapists;
    }
    return selectedTherapists;
  }

  public addMainTherapist(
    mainTherapist: TherapistModel = new TherapistModel(),
  ) {
    this.showWarningMessage = false;
    const mainTherapistFormGroup =
      this.buildMainTherapistFormGroup(mainTherapist);
    this.mainTherapistFormArray.push(mainTherapistFormGroup);
    if (!mainTherapist || Object.keys(mainTherapist).length === 0) {
      this.mainTherapistFormArray.markAllAsTouched();
    }
  }

  private formConfirmSubscription() {
    this.careprogramService.confirmTriggered$.subscribe((confirmed) => {
      if (confirmed) {
        const hasActiveTherapist = (
          this.mainTherapistFormArray.value as Array<any>
        ).some((therapist) =>
          this.careprogramService.checkActiveTherapist(
            therapist.startDate,
            therapist.endDate,
          ),
        );
        if (!hasActiveTherapist) {
          this.errorMessage = this.translate.instant(
            'careprogram.messages.CurrentMainTherapistError',
          );
        } else {
          this.errorMessage = '';
        }
        this.showWarningMessage =
          this.mainTherapistFormArray.length == 0 ||
          this.mainTherapistFormArray.invalid ||
          !hasActiveTherapist;
      }
    });
  }

  public buildMainTherapistFormGroup(mainTherapist?: any): FormGroup {
    const formGroup = this.formBuilder.group({
      employee: this.formBuilder.group({
        id: [mainTherapist.id, Validators.required],
        fullName: [mainTherapist.fullName, Validators.required],
        employeeNumber: [mainTherapist.employeeNumber, Validators.required],
      }),
      startDate: [mainTherapist.startDate, Validators.required],
      endDate: [mainTherapist.endDate],
    });
    if (this.mainTherapistFormArray.disabled) formGroup.disable();

    return formGroup;
  }

  public deleteMainTherapist(index: number): void {
    let confirm$: Observable<boolean>;
    const mainTherapistControl = this.mainTherapistFormArray.get([index]);

    if (!ModelUtils.isObjectEmpty(mainTherapistControl.value)) {
      confirm$ = this.dialog
        .open(DialogComponent, {
          data: {
            title: 'general.messages.DeleteItem',
            message:
              'careprogram.messages.AreYouSureYouWantToDeleteMainTherapist',
            confirmText: 'general.actions.delete',
            method: 'DELETE',
          },
          panelClass: 'dialog-delete',
        })
        .afterClosed();
    } else {
      confirm$ = of(true);
    }

    confirm$.subscribe((res) => {
      if (res) {
        this.mainTherapistFormArray.markAsDirty();
        this.mainTherapistFormArray.removeAt(index);
      }
    });
  }

  public editMainTherapist(mainTherapist: any, index: number) {
    const dialogRef = this.dialog.open(MainTherapistDetailComponent, {
      data: {
        mainTherapist,
        viewOnly: false,
        index: index.toString(),
      },
    });

    dialogRef.afterClosed().subscribe((mainTherapist) => {
      if (mainTherapist) {
        const mainTherapistFormGroup = this.mainTherapistFormArray.get([index]);
        mainTherapistFormGroup.patchValue(mainTherapist);
        mainTherapistFormGroup.markAsDirty();
      }
    });
  }

  public viewMainTherapist(index: number) {
    const mainTherapistFormGroup = this.mainTherapistFormArray.get([
      index,
    ]).value;
    const dialogRef = this.dialog.open(MainTherapistDetailComponent, {
      data: {
        mainTherapist: mainTherapistFormGroup,
        viewOnly: true,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  public createMainTherapist() {
    const dialogRef = this.dialog.open(MainTherapistDetailComponent, {
      data: {
        mainTherapist: null,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((mainTherapist: TherapistModel) => {
      if (mainTherapist) {
        const mainTherapistFormGroup =
          this.buildMainTherapistFormGroup(mainTherapist);
        this.mainTherapistFormArray.push(mainTherapistFormGroup);
        mainTherapistFormGroup.markAsDirty();
        this.mainTherapistFormArray.markAsTouched();
      }
    });
  }
}

<div class="time-picker-container" [formGroup]="timeForm">
  <div class="time-picker-wrapper">
    <button class="up-button" style="left: -2rem" (click)="onHourChange(true)">
      <img src="assets/icons/chevron-up.svg" alt="">
    </button>
    <button class="down-button" style="left: -2rem" (click)="onHourChange(false)">
      <img src="assets/icons/chevron-down.svg" alt="">
    </button>
    <input mask="00"
           formControlName="hour" class="time-picker-input" matInput type="number" min="0" max="23" placeholder="hh" (keydown)="hourRegex($event)"/>
  </div>
  <!-- Colon Separator -->
  <div class="time-picker-colon">:</div>
  <div class="time-picker-wrapper">
    <input formControlName="minute" mask="00" class="time-picker-input" (keydown)="minuteRegex($event)" matInput type="number" min="0" max="59" placeholder="mm" />
    <button class="up-button" style="right: -2rem" (click)="onMinuteChange(true)">
      <img src="assets/icons/chevron-up.svg" alt="">
    </button>
    <button class="down-button" style="right: -2rem" (click)="onMinuteChange(false)">
      <img src="assets/icons/chevron-down.svg" alt="">
    </button>
  </div>
</div>

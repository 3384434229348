import { Component, HostListener, Inject, Input } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-tooltip',
  standalone: true,
  imports: [
    NgClass,
    CommonModule,
  ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent {

  constructor(
    @Inject('content') public content: any,
    @Inject('tooltipClasses') public tooltipClasses: any,
    @Inject('template') public template: any
  ) {
  }
}

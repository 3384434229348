<div class="top-bar-container">

  <div class="top-bar-left-container">
    <span *ngIf="navBarMode === 'navbar-mobile-closed'" class="top-bar-icon" (click)="onNavBarOpenClicked($event)">
      <mat-icon class="c4p-top-icons">menu</mat-icon>
    </span>
    <app-breadcrumb></app-breadcrumb>
  </div>

  <div class="top-bar-right-container">
    <span class="top-bar-icon">

      <app-language-picker></app-language-picker>
    </span>
    <span class="top-bar-icon" *ngIf="hasNotificationPermission">

      <span class="top-bar-button" (click)="onToggleNotification()">
        <mat-icon
          [matBadge]="count$ | async"
          matBadgeColor="warn"
          matBadgeSize="small"
          [matBadgeHidden]="count$.value === 0"
          aria-hidden="false">notifications</mat-icon>
      </span>
    </span>
    <span class="top-bar-icon">
      <app-user-menu (roleChanged)="onRoleChanged()"></app-user-menu>
    </span>

  </div>
</div>

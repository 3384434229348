import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {MEDICATION} from '../../../../c4p-portal-employee/src/app/client/const/validation';

export function bloodPressureValidatorFactory(translate: TranslateService): ValidatorFn {
  let timeout: any;

  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }
    if (timeout) {
      clearTimeout(timeout);
    }

    let validationErrors: ValidationErrors | null = null;

    timeout = setTimeout(() => {
      const allowedPattern = /^[0-9\/]*$/;
      if (!allowedPattern.test(value)) {
        validationErrors = { bloodPressureFormat: translate.instant('medication.messages.BloodPressureFormat') };
      }
      const parts = value.split('/');
      if (value === '/') {
        validationErrors = { bloodPressureFormat: translate.instant('medication.messages.BloodPressureFormat') };
      }
      if (parts.length === 2) {
        const systolic = parseInt(parts[0], 10);
        const diastolic = parseInt(parts[1], 10);

        if (!parts[0] || isNaN(systolic)) {
          validationErrors = { ...validationErrors, systolicError: translate.instant('medication.messages.SystolicRequired') };
        } else if (systolic < MEDICATION.VALIDATION.BLOOD_PRESSURE_SYSTOLIC_MIN || systolic > MEDICATION.VALIDATION.BLOOD_PRESSURE_SYSTOLIC_MAX) {
          validationErrors = { ...validationErrors, systolicError: translate.instant('medication.messages.SystolicOutOfRange') };
        }

        if (!parts[1] || isNaN(diastolic)) {
          validationErrors = { ...validationErrors, diastolicError: translate.instant('medication.messages.DiastolicRequired') };
        } else if (diastolic < MEDICATION.VALIDATION.BLOOD_PRESSURE_DIASTOLIC_MIN || diastolic > MEDICATION.VALIDATION.BLOOD_PRESSURE_DIASTOLIC_MAX) {
          validationErrors = { ...validationErrors, diastolicError: translate.instant('medication.messages.DiastolicOutOfRange') };
        }
      } else if (parts.length !== 2) {
        validationErrors = { ...validationErrors, bloodPressureFormat: translate.instant('medication.messages.BloodPressureFormat') };
      }

      control.setErrors(validationErrors);
    }, 1500);

    return null;
  };
}

<mat-form-field class="custom-input-form">
  <mat-label class="custom-input-label">{{ label | translate }}</mat-label>

  <div
  class="custom-input"
  [attr.disabled]="disabled ? '' : null"
  >
  
  @if(prefix){
    <span class="input-prefix" matPrefix>{{ prefix }}</span>
  }

  @if(type==='number'){
    <input
      [attr.testId]="testId"
      [formControl]="control"
      [type]="type"
      matInput
      placeholder="{{ placeholder | translate }}"
      [step]="step"
      [min]="minNumberValue"
      [max]="maxNumberValue"
    />
  }@else {
    <input
      [attr.testId]="testId"
      [formControl]="control"
      [type]="type"
      matInput
      placeholder="{{ placeholder | translate }}"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [maxLength]="maxStringLength"
    />
  }
  @if(suffix){
    <span class="input-suffix" matSuffix>{{ suffix }}</span>
  }

  </div>

  <mat-hint class="custom-input-hint" align="{{alignHint}}">{{ hint ?? '' }}</mat-hint>

  <mat-error class="custom-input-error">
    @if(control?.errors){
    <app-validation-message [errors]="control.errors"></app-validation-message>
    }
  </mat-error>

</mat-form-field>

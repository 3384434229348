<div class="upload-container"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     (drop)="onDrop($event)">
  <div style="cursor: pointer" (click)="openFileBrowser()">
    <i class="ai-icon-upload-cloud-02"></i>
    <div class="upload-text" translate>document.labels.clickToUpload</div>
    <div class="upload-subtext" translate>document.labels.fillInRequiredData</div>
  </div>
</div>

<div style="overflow: auto">
  @for (file of files; track file; ) {
    <app-document-upload-detail [document]="file" [categories]="categories" [services]="sideSheetContent.data.services" [carefileId]="carefileId" [applicationId]="applicationId" [careprogramId]="careprogramId" [clientId]="clientId" (delete)="deleteDocument(file)"></app-document-upload-detail>
  }
</div>

<input #fileInput type="file" style="display: none" multiple (change)="onFileSelected($event)" />


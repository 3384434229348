import { NgModule } from '@angular/core';

import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { FormInputTextAreaNdComponent } from './form-input-text-area-nd/form-input-text-area-nd.component';
import { FormInputCheckBoxNdComponent } from './form-input-checkbox-nd/form-input-checkbox-nd.component';
import { FormInputTextNdComponent } from './form-input-text-nd/form-input-text-nd.component';
import { FormInputRadioNdComponent } from './form-input-radio-nd/form-input-radio-nd.component';
import { FormInputDropdownNdComponent } from './form-input-dropdown-nd/form-input-dropdown-nd.component';
import { TablePaginationComponent } from './pagination-nd/table-pagination.component';
import { FormInputDateNdComponent } from './form-input-date-nd/form-input-date-nd.component';
import { FormInputDateRangeNdComponent } from './form-input-date-range-nd/form-input-date-range-nd.component';
import { AppFormInputMonthYearNdComponent } from './app-form-input-month-year-nd/app-form-input-month-year-nd.component';
import { AppFormInputDateTimeNdComponent } from './app-form-input-date-time-nd/app-form-input-date-time-nd.component';

const moduleComponents = [
  FormInputTextNdComponent,
  ValidationMessageComponent,
  FormInputTextAreaNdComponent,
  FormInputCheckBoxNdComponent,
  FormInputRadioNdComponent,
  FormInputDropdownNdComponent,
  TablePaginationComponent,
  FormInputDateNdComponent,
  FormInputDateRangeNdComponent,
  AppFormInputMonthYearNdComponent,
  AppFormInputDateTimeNdComponent
];
@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class FormControlsNdModule {}

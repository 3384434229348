
import { Component, HostListener, input, model, output, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'app-dialog-delete',
    templateUrl: './dialog-delete.component.html',
    styleUrls: ['./dialog-delete.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogDeleteComponent  {
    isAgreed= model(false);
    title=input('');
    message=input('');
    label=input('');
    isRequireConfirmation = input(false);
    confirmText = input('');
    confirm = output();
    dismiss = output();


    onConfirm() {
      this.confirm.emit();
    }
  
    onDismiss() {
      this.dismiss.emit();
    }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if(event.key==="Escape"){
      this.onDismiss()
    }else if(event.key==="Enter" && this.isAgreed()){
      this.onConfirm()
    }
  }

}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataWrapper } from '../../models/gatekeeper/data-wrapper.model';
import { GatekeeperModel } from '../../models/gatekeeper/gatekeeper.model';
import { LanguageService } from '../../services/language/language.service';

export interface IGatekeeper {
  // Popup dialog title
  title: string;

  // Reason label, if it is not provided then we have popup without reason
  reason_label?: string;

  // Checkbox label, if it is not provided then we have popup without check list
  checkbox_label?: string;

  // Check list
  checkbox_description?: string;

  // Message for checkbox
  checkbox_message?: string;

  // OK button text
  confirm_text?: string;
}

@Component({
  selector: 'app-gatekeeper',
  templateUrl: './gatekeeper.component.html',
  styleUrls: ['./gatekeeper.component.scss'],
})
export class GatekeeperComponent implements OnInit {
  public reason: string = '';
  public isAgreed: boolean = false;
  public selectedLanguage: number;

  constructor(
    public dialogRef: MatDialogRef<GatekeeperComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: GatekeeperModel,
    public languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.languageService.languageObservable.subscribe((l) => {
      if (l === 'nl') {
        this.selectedLanguage = 0;
      } else {
        this.selectedLanguage = 1;
      }
    });
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    const dataWrapper: DataWrapper = new DataWrapper();
    dataWrapper.reason = this.reason;
    dataWrapper.confirmed = this.isAgreed;
    this.dialogRef.close(dataWrapper);
  }
}

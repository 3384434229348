import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  Input,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import {
  DEFAULT_RADIO_DIRECTION,
  Option,
} from '../../form-controls/form-controls.const';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-radio',
  templateUrl: './form-input-radio-nd.component.html',
  styleUrls: ['./form-input-radio-nd.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    ValidationMessageComponent,
  ],
})
export class FormInputRadioNdComponent {
  @Input({
    required: true,
    transform: (value: string) => {
      return 'radio-input-' + value;
    },
  })
  testId: string;

  @Input() options: Option[] = [];

  @Input() disabled: boolean = false;

  @Input() controlName: string; // Name of the form control
  @Input() control: AbstractControl;

  @Input() groupHint: string = '';

  @Input() direction: 'row' | 'column' = DEFAULT_RADIO_DIRECTION;
  @Input() size: 'md-size' | 'sm-size' = 'sm-size';
  @Input() label: string;
  @Input() radioLabelTemplate: TemplateRef<any>;
  @Input() radioStyleWrapperContainer: string = '';
  @Input() radioGroupContainer: string = '';
  @Input() radioButtonStyle: string = '';
  @Input() labelStyle: string = '';

  public required = Validators.required;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }
  }
}

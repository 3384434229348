import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-panel-title',
  templateUrl: './panel-title.component.html',
  styleUrls: ['./panel-title.component.scss'],
})
export class PanelTitleComponent implements OnInit, OnChanges {
  @Input() isCustomTitleClassActive: boolean = false;
  @Input() style: string = '';
  @Input() addHorizantalDivider: boolean = true;
  @Input() title: string;
  @Input() subTitle1: string;
  @Input() subTitle2: string;
  @Input() status: boolean;
  @Input() topStatus: string;
  @Input() isActive: boolean;
  @Input() titleIcon: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = changes.title.currentValue;
  }
}

export const FILE_TYPES: { [key: string]: string } = {
  pdf: 'pdf.svg',
  doc: 'doc.svg',
  docx: 'docx.svg',
  csv: 'csv.svg',
  ppt: 'ppt.svg',
  pptx: 'pptx.svg',
  txt: 'txt.svg',
  xls: 'xls.svg',
  xlsx: 'xlsx.svg',
  default: 'default-file.svg'
};

export const ALLOWED_FILE_TYPES = [
    'doc',
    'docx',
    'odt',
    'ods',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'txt',
    'pdf',
    'bmp',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'mp3',
    'aac',
    'flac',
    'mp4',
    'avi',
    'mkv',
    'eml',
    'emlx',
    'msg',
    'json',
    'mpg',
    'mpeg',
  ];

export const MAXIMUM_FILE_SIZE = 20971520; // 20MB

export const MAXIMUM_FILE_LIMIT = 5;

import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CommandLinePosition, SideSheetButtonEvent, SideSheetButtonListener, SideSheetContent, SideSheetSize } from '../../../models';
import { SideSheetService } from '../../../services';
import { SideSheetExample2Component } from './side-sheet-example2.component';
import { SIDE_SHEET_CONTENT } from '../../../models/side-sheet/side-sheet-token';

@Component({
  selector: 'app-side-sheet-example',
  templateUrl: './side-sheet-example.component.html',
})
export class SideSheetExampleComponent implements OnInit, OnDestroy, SideSheetButtonListener {
  @ViewChild('sidesheetContentFooterTemplate', { static: true }) sidesheetContentFooterTemplate: TemplateRef<any>;

  form: FormGroup = this.fb.group({
    name: new FormControl(''),
    testName: new FormControl(''),
  });

  eventText = "No event";

  constructor(
    private fb: FormBuilder,
    @Inject(SIDE_SHEET_CONTENT) public sideSheetContent: SideSheetContent,
    private sideSheetService : SideSheetService
  ) {
    console.log("ExampleComponent constructor");
  }
  onClickButton(event: SideSheetButtonEvent) {
   this.sideSheetService.closeSideSheet();
  }

  ngOnInit() {
    console.log("ExampleComponent ngOnInit");
  }


  ngOnDestroy(): void {
    console.log("ExampleComponent ngOnDestroy");
  }

  loadExample2Component() {
    const content: SideSheetContent = new SideSheetContent();
    content.title = 'Example Component 2';
    content.buttons = [
      this.sideSheetService.createCloseButton(),
      this.sideSheetService.createCreateButton(),
      this.sideSheetService.createEditButton(),
      this.sideSheetService.createDeleteButton(),
    ];

    const sideSheetRef = this.sideSheetService.loadComponent(SideSheetExample2Component, content, {
      size: SideSheetSize.MD,
      commandLineVisible: true,
      commandLinePosition: CommandLinePosition.BOTTOM,
    });

    sideSheetRef.afterClosed().subscribe((data)=>{
      console.log("example 1 get from example 2 ", data);
    });
  }
}

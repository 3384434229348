import { Component, Input, Output, EventEmitter, OnInit, numberAttribute, booleanAttribute } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSelectChange } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

export class PageEvent {
  /** The current page index. */
  pageIndex: number;
  /** The previous page index. */
  previousPageIndex?: number;
  /** The current page size. */
  pageSize: number;
  /** The current total number of items being paged. */
  length: number;
}

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule, TranslateModule, MatSelect, MatOption],
})
export class TablePaginationComponent implements OnInit {

  @Input({ transform: numberAttribute })
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = Math.max(value || 0, 0);
  }
  private _pageIndex = 0;


  @Input({ transform: numberAttribute })
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value || 0;
  }
  private _length = 0;


  @Input({ transform: numberAttribute })
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = Math.max(value || 0, 0);
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSize: number = 10;

  @Input()
  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(value: number[] | readonly number[]) {
    this._pageSizeOptions = (value || ([] as number[])).map(p => numberAttribute(p, 0));
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSizeOptions: number[] = [];

  @Input({transform: booleanAttribute})
  disabled: boolean = false;

  @Output() pageChange = new EventEmitter<PageEvent>();

  _displayedPageSizeOptions: number[];



  constructor() { }

  ngOnInit(): void {
    this._updateDisplayedPageSizeOptions();
  }


  onItemsPerPageChange(event: MatSelectChange): void {
    const pageSize = event.value;
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this.emitPageChange(previousPageIndex);
  }

  goToPreviousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    this.emitPageChange(previousPageIndex);
  }

  goToNextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    this.emitPageChange(previousPageIndex);
  }

  private emitPageChange(previousPageIndex: number) {
    this.pageChange.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }


  getStartItem(): number {
    return (this.pageIndex * this.pageSize) + 1;
  }

  getEndItem(): number {
    return Math.min((this.pageIndex + 1) * this.pageSize, this.length);
  }

  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize != 0;
  }


  hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize != 0;
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    return Math.ceil(this.length / this.pageSize);
  }

  private _updateDisplayedPageSizeOptions() {
    if (!this.pageSize) {
      this._pageSize =
        this.pageSizeOptions.length != 0 ? this.pageSizeOptions[0] : 10;
    }

    this._displayedPageSizeOptions = this.pageSizeOptions.slice();

    if (this._displayedPageSizeOptions.indexOf(this.pageSize) === -1) {
      this._displayedPageSizeOptions.push(this.pageSize);
    }

    this._displayedPageSizeOptions.sort((a, b) => a - b);
  }
}

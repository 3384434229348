    <mat-form-field class="dropdown-form-field">
        <mat-label>{{label | translate}}</mat-label>

        <div class="custom-dropdown" 
        [attr.disabled]="disabled ? '' : null"
        [attr.search]="search ? '' : null">
        
          @if (search) {
            <i matPrefix class="ai-icon-search-lg icon-prefix"></i>
          }
  
          <mat-select  [attr.testId]="testId"  panelClass="panel-dropdown-select"   [formControl]="control" [multiple]="multiSelection" [panelWidth]="maxContentSelectionPanel ? null : 'auto'">
  
            @if(search){
              <mat-option class="ngx-wrapper-search">
                <ngx-mat-select-search
                  [showToggleAllCheckbox]="multiSelection"
                  [toggleAllCheckboxIndeterminate]="isIndeterminate()"
                  [toggleAllCheckboxChecked]="isChecked()"
                  (toggleAll)="toggleSelectAll($event)"
  
                  class="ngx-search"
                  [formControl]="searchControl"
                  [clearSearchInput]="false"
                  [searching]="isSearchResultLoading"
                  placeholderLabel="{{ 'general.actions.Search' | translate }}..."
                  noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}">
                </ngx-mat-select-search>
              </mat-option>
            }  
            
            @if (!multiSelection) {
              <mat-option>&nbsp;</mat-option>
            }
  
  
            @for (option of filteredOptions(); track $index) {
                    <mat-option [value]="{option,objectAsValue} | showFunction:putValue">{{option | showFunction:optionDisplayFunction | translate}}</mat-option>
            }
  
          </mat-select>
        </div>
        


        <mat-hint class="custom-dropdown-hint" align="{{alignHint}}">{{ hint ?? '' }}</mat-hint>

        <mat-error class="custom-dropdown-error">
          @if(control?.errors){
          <app-validation-message [errors]="control.errors"></app-validation-message>
          }
        </mat-error>
    </mat-form-field>


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogOption {
  // Popup dialog title
  title: string;

  // Message in dialog body
  message: string;

  // Text for confirmation button
  confirmText: string;

  // Text for cancel button
  cancelText?: string;

  reasonLabel?: string;

  onlyConfirmation?: boolean;

  smallPopup?: boolean;

  requireConfirmationCheckbox?: boolean;

  checkboxStatus: string;

  method?: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  public reason: string;
  public isConfirmed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogOption,
  ) {
    if (data?.method === 'DELETE' && !data?.requireConfirmationCheckbox) {
      this.isConfirmed = true;
    }
  }

  ngOnInit(): void {}

  public onDismiss(): void {
    this.dialogRef.close(false);
  }

  public onConfirm(): void {
    if (this.data.requireConfirmationCheckbox) {
      this.dialogRef.close(this.isConfirmed ? { reason: this.reason } : false);
    } else {
      this.dialogRef.close({ reason: this.reason });
    }
  }
}

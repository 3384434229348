export const MEDICATION= {
  VALIDATION: {
    WEIGHT_MIN: 15,
    WEIGHT_MAX: 300,
    HEIGHT_MIN: 50,
    HEIGHT_MAX: 250,
    HEART_RATE_MIN: 0,
    HEART_RATE_MAX: 500,
    BLOOD_PRESSURE_SYSTOLIC_MIN: 50,
    BLOOD_PRESSURE_SYSTOLIC_MAX: 250,
    BLOOD_PRESSURE_DIASTOLIC_MIN: 30,
    BLOOD_PRESSURE_DIASTOLIC_MAX: 150
  }
}

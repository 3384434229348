import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'tenant-management',
    data: {
      breadcrumbLabel: 'general.menu.Tenant',
    },
    loadChildren: () =>
      import('../tenant-management/tenant-management.module').then(
        (m) => m.TenantManagementModule,
      ),
  },
  {
    path: 'pharmacy-management',
    data: {
      breadcrumbLabel: 'general.menu.Pharmacy',
    },
    loadChildren: () =>
      import('../pharmacy-management/pharmacy-management.module').then(
        (m) => m.PharmacyManagementModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

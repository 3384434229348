<div
  class="custom-month-year-input-form"
  [attr.isDisabled]="disabled || null"
>
  <mat-form-field>
    <mat-label
      class="custom-month-year-label"
    >
      {{ label | translate }}</mat-label
    >
    <div class="custom-month-year-input">
      <input
        matInput
        [formControl]="control"
        [matDatepicker]="datePicker"
        [required]="required"
        [min]="minDateValue"
        [max]="maxDateValue"
        maxlength="10"
        (keydown)="dateMonthYearRegex($event)"
        (dateChange)="onChangeDate()"
        (change)="valueChangeHandler($event)"
        [attr.testId]="testId"
      />
    </div>
    <mat-hint>{{ hint | translate }}</mat-hint>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="datePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker
      panelClass="custom-mat-month-year-panel-class"
      #datePicker
      (opened)="onDatepickerOpened()"
      (closed)="onDatepickerClosed()"
      startView="multi-year"
      (monthSelected)="setMonthAndYear($event, datePicker)"
    ></mat-datepicker>
    <mat-error>
      @if(control?.errors){
        <app-validation-message
          [errors]="control.errors"
        ></app-validation-message>
      }
    </mat-error>
  </mat-form-field>
</div>


<div class="gatekeeper-container">
  <div class="gatekeeper-header">
    <div class="title-popup" translate>{{ title() }}</div>
  </div>
  <div class="gatekeeper-body">
    <div class="gatekeeper-content" translate [innerHTML]="message()">
    </div>
    @if(isRequireConfirmation()){
    <div class="gatekeeper-toggle-slider" [attr.checked]="isAgreed() ? '' : null">
      <mat-slide-toggle [(ngModel)]="isAgreed" translate> {{label()}} </mat-slide-toggle>
    </div>
    }

    <div class="gatekeeper-footer" [attr.confirmed]="isRequireConfirmation() ? '' : null">
      @if(isAgreed()){
      <button class="confirm-btn" (click)="onConfirm()" translate>{{confirmText() || 'general.labels.Yes'}}</button>
      <button class="cancel-btn" (click)="onDismiss()" translate>general.labels.No</button>
      }@else {
      <button class="cancel-btn" (click)="onDismiss()" translate>general.labels.Cancel</button>
      }
    </div>
  </div>
</div>
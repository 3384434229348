<div
  class="custom-datetime-input-form"
  [attr.isDisabled]="disabled || null"
>
  <mat-form-field>
    <mat-label
      class="custom-datetime-label"
    >
      {{ label | translate }}</mat-label
    >
    <div class="custom-datetime-input">
      <input
        matInput
        [formControl]="control"
        [matDatepicker]="datePicker"
        [required]="required"
        [min]="minDateValue"
        [max]="maxDateValue"
        maxlength="16"
        (keydown)="datetimeregex($event)"
        #datePickerInput
        (dateChange)="onChangeDate()"
        (change)="valueChangeHandler($event)"
        [attr.testId]="testId"
      />
    </div>
    <mat-hint>{{ hint | translate }}</mat-hint>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="datePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker
      panelClass="custom-mat-datetime-panel-class"
      #datePicker
      [startView]="startView"
      (monthSelected)="chosenMonthHandler($event, datePicker)"
      (opened)="onDatepickerOpened()"
      (closed)="onDatepickerClosed()"
    ></mat-datepicker>
    <mat-error>
      @if(control?.errors){
        <app-validation-message
          [errors]="control.errors"
        ></app-validation-message>
      }
    </mat-error>
  </mat-form-field>
</div>

<div class="n-container">

  <div class="n-list">
    <ul>
      <ng-container *ngFor="let notification of page?.docs; let i =index">
        <div class="n-divider"
          *ngIf="i === 0 || (page?.docs[i]?.createdAt | dayDivisionPipe) !== (page?.docs[i-1]?.createdAt | dayDivisionPipe)">
          <div class="n-sub-divider"></div>
          <div class="n-sub-divider-text">{{ page?.docs[i]?.createdAt | dayDivisionPipe }}</div>
          <div class="n-sub-divider"></div>
        </div>

        <li [ngClass]="{'li-bg': notification.currentUserRead}">
          <div class="n-li">
            <div class="li-sub-item n-dot">

              <div class="tooltip" *ngIf="!notification.currentUserRead" (click)="onReadUnreadFlag(notification)">
                <img src="/assets/svg/active-success-dot.svg" alt="">
                <div class="tooltiptext">{{'notification.labels.MarkAsRead' | translate}}</div>
              </div>
              <div class="tooltip" *ngIf="notification.currentUserRead" (click)="onReadUnreadFlag(notification)">
                <img src="/assets/svg/dot.svg" alt="">
                <div class="tooltiptext">{{'notification.labels.MarkAsUnread' | translate}}</div>
              </div>
            </div>
            <div class="li-sub-item li-sub-item-title">
              <span class="li-sub-title"
                (click)="onToggleNotificationContent(notification, false)">{{notification?.subject}}</span>
              <span class="li-sub-date">{{ notification?.createdAt | notificationDate }}</span>
            </div>
            <div class="li-sub-item n-dot">
              <div class="tooltip-star" *ngIf="notification.currentUserStarred"
                (click)="onChangeStar(notification,false)">
                <img src="/assets/svg/star-success.svg" alt="">
                <div class="tooltiptext-star"> {{'notification.labels.RemoveFromStar' | translate}}</div>
              </div>
              <div class="tooltip-star" *ngIf="!notification.currentUserStarred"
                (click)="onChangeStar(notification,true)">
                <img src="/assets/svg/star-passive.svg" alt="">
                <div class="tooltiptext-star"> {{'notification.labels.AddToStar' | translate}}</div>
              </div>
            </div>
          </div>

          <div class="n-li-content" *ngIf="selectedNotification && selectedNotification?.id === notification?.id">
            {{notification?.message}}
          </div>
        </li>
      </ng-container>
    </ul>

  </div>

</div>

<ng-template #sidesheetContentHeaderTemplate>
  <div class="n-header">

    <div class="h-col-sub">
      <button class="notify-filter" (click)="onToggleFilterPanel()">
        <img src="/assets/icons/filter.svg" alt="" />
        <span class="notify-filter-text">{{'notification.labels.Filters' | translate}}</span>
        <div class="arrow">
          <img src="/assets/icons/arrow-down.svg" alt="" *ngIf="!showFilterPanel" />
          <img src="/assets/icons/arrow-up.svg" alt="" *ngIf="showFilterPanel" />
        </div>
      </button>
    </div>

    <div class="h-col-sub">
      <mat-slide-toggle [(ngModel)]="filterParams.unreadSelected" (change)="onFilterParamsChanged($event)">
        <span class="notify-toggle-text">{{'notification.labels.ShowUnread' | translate}}</span>
      </mat-slide-toggle>
    </div>

    <div class="h-col-sub">
      <mat-slide-toggle [(ngModel)]="filterParams.starredSelected" (change)="onFilterParamsChanged($event)">
        <span class="notify-toggle-text">{{'notification.labels.Starred' | translate}}</span>
      </mat-slide-toggle>

    </div>


    <div class="h-col-sub">
      <button class="notify-read" (click)="onOpenMarkAllAsReadTooltip($event)">
        <img src="/assets/icons/check.svg" alt="" />
        {{'notification.labels.MarkAllMessages' | translate}}
      </button>

      <ng-container *ngIf="markAllTooltipOpen">
        <app-notification-tooltip (closeTooltip)="onCloseMarkAllAsReadTooltip($event)"
          (markAllAsRead)="onMarkAllAsRead($event)" [notificationCount]="page.totalDocs"
          [notificationLimit]="page.limit"></app-notification-tooltip>
      </ng-container>

    </div>

  </div>

  <ng-container *ngIf="showFilterPanel">
    <div class="n-filter">
      <mat-form-field appearance="outline" class="n-form-input-field">
        <input matInput placeholder="{{'notification.labels.Search' | translate }}" [(ngModel)]="filterParams.search"
          (keyup)="onFilterParamsChanged($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="n-filter">
      <div class="h-col">
        <mat-form-field appearance="outline" class="n-form-input-field">
          <input matInput [matDatepicker]="fromDatepicker" placeholder="{{'notification.labels.From' | translate }}"
            (click)="fromDatepicker.open()" [(ngModel)]="filterParams.fromDate"
            (dateChange)="onFilterParamsChanged($event)" (dateInput)="onFilterParamsChanged($event)"
            [max]="filterParams.untilDate">
          <mat-datepicker-toggle matIconSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="h-col">
        <mat-form-field appearance="outline" class="n-form-input-field">
          <input matInput [matDatepicker]="untilDatePicker" placeholder="{{'notification.labels.Until' | translate }}"
            (click)="untilDatePicker.open()" [(ngModel)]="filterParams.untilDate"
            (dateChange)="onFilterParamsChanged($event)" [min]="filterParams.fromDate">
          <mat-datepicker-toggle matIconSuffix [for]="untilDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #untilDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #sidesheetContentFooterTemplate>

  <div class="n-paginator">
    <mat-paginator *ngIf="page" [length]="page.totalDocs" [pageSize]="page.limit" [pageIndex]="page.page-1"
      [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)">
    </mat-paginator>
  </div>

</ng-template>


<ng-template #sidesheetBreadcrumbActionsTemplate>
  <span style="margin-top:0.6rem">
     <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onClickSettings()">
    <mat-icon>settings</mat-icon>
  </button>
  <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onToggleFullScreen()">
    <mat-icon> {{sideSheetConfig?.fullscreen ? "fullscreen_exit": "fullscreen"}}</mat-icon>
  </button>
  <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onSideSheetCloseButton()">
    <mat-icon>close</mat-icon>
  </button>
  </span>

</ng-template>

<mat-card appearance="outlined" class="c4p-card-view">
  <ng-container *ngFor="let field of firstPart">
    <div
      *ngIf="field.format == 'title'"
      style="margin: 10px 0 20px 0"
      class="c4p-card-view-title clickable-link"
      (click)="openEditOrView()"
    >
      <div
        *ngIf="field.type == 'string'"
        class="c4p-card-view-value c4p-ellipsis"
        style="margin: 4px 12px"
      >
        <span class="c4p-font-large">{{ data[field.id] }}</span>
      </div>
    </div>
    <ng-container *ngIf="field.type == 'icon' && data[field.id] === true">
      <div style="float: right; margin-top: 10px">
        <mat-icon
          matPrefix
          class="c4p-action-icons"
          [matTooltip]="field.iconTooltip | translate"
          >{{ field.format }}</mat-icon
        >
      </div>
    </ng-container>
  </ng-container>

  <div style="display: flex">
    <div class="c4p-card-view-left">
      <div>
        <ng-container *ngFor="let field of firstPart">
          <div
            *ngIf="!field.hideLabel"
            class="c4p-font-normal c4p-dimmed c4p-ellipsis c4p-card-view-label"
            translate
          >
            {{ field.name }}
          </div>

          <div *ngIf="field.type == 'status'" class="c4p-card-view-value">
            <app-status [value]="data[field.id]"></app-status>
          </div>

          <!-- <div *ngIf="field.type == 'string' && field.format == 'title'" class="c4p-card-view-value c4p-ellipsis">
            <span class="c4p-font-large">{{ data[field.id] }}</span>
          </div> -->

          <div
            *ngIf="field.type == 'string' && field.format != 'title'"
            class="c4p-card-view-value c4p-ellipsis"
          >
            <span class="c4p-font-normal">{{ data[field.id] }}</span>
          </div>

          <div *ngIf="field.type == 'boolean'" class="c4p-card-view-value">
            <mat-icon
              matPrefix
              class="c4p-indicator-icon c4p-indicator-icon-{{ data[field.id] }}"
            >
              {{ field.format }}</mat-icon
            >
          </div>

          <div
            *ngIf="field.type == 'date'"
            class="c4p-card-view-value c4p-ellipsis"
          >
            <span class="c4p-font-normal">{{ data[field.id] | appDate }}</span>
          </div>

          <div
            *ngIf="field.type == 'objectList'"
            class="c4p-card-view-value-list"
          >
            <span class="c4p-font-normal c4p-ellipsis">
              <app-object-list [data]="data[field.id]"></app-object-list>
            </span>
          </div>

          <div *ngIf="field.type == 'avatar'">
            <span style="display: flex; justify-content: center">
              <ngx-avatars
                [name]="data[field.avatarName]"
                [src]="data[field.id]"
                size="80"
                bgColor="#FFFFFF"
                fgColor="#808080"
              ></ngx-avatars>
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="c4p-card-view-right">
      <ng-container *ngFor="let field of secondPart" style="padding-top: 12px">
        <div
          *ngIf="!field.hideLabel"
          class="c4p-font-normal c4p-dimmed c4p-ellipsis c4p-card-view-label"
          translate
        >
          {{ field.name }}
        </div>

        <div *ngIf="field.type == 'status'" class="c4p-card-view-value">
          <app-status [value]="data[field.id]"></app-status>
        </div>

        <div
          *ngIf="field.type == 'string' && field.format == 'title'"
          class="c4p-card-view-value c4p-ellipsis"
        >
          <span class="c4p-font-large">{{ data[field.id] }}</span>
        </div>

        <div
          *ngIf="field.type == 'string' && field.format != 'title'"
          class="c4p-card-view-value c4p-ellipsis"
        >
          <span class="c4p-font-normal">{{ data[field.id] }}</span>
        </div>

        <div *ngIf="field.type == 'boolean'" class="c4p-card-view-value">
          <mat-icon
            matPrefix
            class="c4p-indicator-icon c4p-indicator-icon-{{ data[field.id] }}"
          >
            {{ field.format }}</mat-icon
          >
        </div>

        <div
          *ngIf="field.type == 'date'"
          class="c4p-card-view-value c4p-ellipsis"
        >
          <span class="c4p-font-normal">{{
            data[field.id] | date: field.format
          }}</span>
        </div>

        <div
          *ngIf="field.type == 'objectList'"
          class="c4p-card-view-value-list"
        >
          <span class="c4p-font-normal c4p-ellipsis">
            <app-object-list [data]="data[field.id]"></app-object-list>
          </span>
        </div>

        <div *ngIf="field.type == 'avatar'">
          <span style="display: flex; justify-content: center">
            <ngx-avatars
              [name]="data[field.avatarName]"
              [src]="data[field.id]"
              size="80"
              bgColor="#FFFFFF"
              fgColor="#808080"
            ></ngx-avatars>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="c4p-card-view-footer"
    *ngIf="menuActions && menuActions.length > 0"
  >
    <span *ngFor="let action of menuActions">
      <a
        (click)="action.method(data)"
        matTooltip="{{ action.tooltip | translate }}"
      >
        <mat-icon matPrefix class="c4p-action-icons">{{
          action.icon
        }}</mat-icon>
      </a>
    </span>
  </div>
</mat-card>

<mat-menu #menu="matMenu" class="c4p-menu">
  <ng-template matMenuContent let-name="name" let-rowIndex="rowIndex">
    <div *ngFor="let action of menuActions">
      <div
        mat-menu-item
        class="c4p-menu-item"
        (click)="action.method(options.data)"
      >
        <mat-icon class="c4p-action-menu-icon">{{ action.icon }}</mat-icon>
        <span class="c4p-menu-title">{{ action.tooltip | translate }}</span>
      </div>
    </div>
  </ng-template>
</mat-menu>

<ng-container>
  <div class="document-container" [ngStyle]="{'border-color': uploadFailed ? 'red' : ''}">
    <div class="file-header">
      <img src="{{iconPath}}" class="file-icon" alt="">
      <div style="display: flex; flex-direction: column; flex-grow: 1">
        <div style="display: flex; flex-direction: row">
          <div class="file-info">
            <div class="file-name">{{ document.name }}</div>
            <div class="file-size">{{ HttpUtils.calculateFileSize(document.size) }}</div>
          </div>
          <div style="display: flex; align-items: center;" *ngIf="uploadProgress === 100">
            <i class="ai-icon-check" style="background-color: var(--primary-color-600); color: white; border-radius: 50%"></i>
          </div>
          <button class="file-action-button" (click)="deleteDocument()" [disabled]="uploadProgress !== 0">
            <i class="ai-icon-trash-01"></i>
          </button>
        </div>
        <div class="custom-progress-container">
          <div style="height: 8px; width: 100%; border-radius: 4px"
               [ngStyle]="{backgroundColor: uploadFailed ? 'red' : 'var(--gray-color-200)'}">
          <div
              class="progress-bar"
              [ngStyle]="{ width: uploadProgress + '%', backgroundColor: 'var(--primary-color-600)' }"
            >
            </div>
          </div>
          <span class="progress-label">{{ uploadProgress }}%</span>
        </div>
        <div>
          <span
            [ngStyle]="{color: uploadFailed ? 'red' : ''}">
            {{ uploadStatus | translate }}</span>
        </div>
      </div>
      <div class="file-actions">
        <button class="toggle-button" mat-icon-button *ngIf="!panelExpanded" (click)="togglePanel()">
          <i class="ai-icon-chevron-down"></i>
        </button>
        <button class="toggle-button" mat-icon-button *ngIf="panelExpanded" (click)="togglePanel()">
          <i class="ai-icon-chevron-up"></i>
        </button>
      </div>
    </div>
    <div class="document-details" *ngIf="panelExpanded" [formGroup]="documentForm">
      <app-nd-form-input-dropdown
        [testId]="'document-upload-detail-categories-dropdown'"
        label="document.labels.Category"
        controlName="category"
        [options]="categories"
        [multiSelection]="false"
        [labelProperty]="'name'"
        [objectAsValue]="true"
      ></app-nd-form-input-dropdown>
      <app-nd-form-input-dropdown
        [testId]="'document-upload-detail-services-dropdown'"
        label="general.labels.Services"
        controlName="services"
        [options]="services"
        [multiSelection]="true"
        [labelProperty]="'name'"
        [objectAsValue]="true"
      ></app-nd-form-input-dropdown>
      <app-nd-form-input-text
        [testId]="'document-upload-detail-textarea'"
        label="general.labels.Description"
        controlName="description"
      ></app-nd-form-input-text>
    </div>
  </div>
</ng-container>

<app-document-overview
  [applicationViewMode]="applicationViewMode"
  [careprogramViewMode]="careprogramViewMode"
  [userPortal]="userportal"
  [operation]="operation"
  [section]="section"
  [services]="services"
  [careprogramId]="careprogramId"
  [clientId]="clientId"
></app-document-overview>

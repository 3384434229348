import { Component } from '@angular/core';
import { AppInitializerService } from 'c4p-portal-util';
import { MAIN_MENU_ITEMS } from './const/values.const';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appInitializerService: AppInitializerService
  ) {
    this.registerIcons();
  }

  ngOnInit() {
    this.appInitializerService.initializeApp(MAIN_MENU_ITEMS);
  }

  registerIcons(){
    this.matIconRegistry.addSvgIcon('flag-en', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/gb.svg'));
    this.matIconRegistry.addSvgIcon('flag-nl', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/nl.svg'));
  }
  
}

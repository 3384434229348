import { IMainMenuItem } from 'c4p-portal-util';

const MAIN_MENU_ITEMS: IMainMenuItem[] = [
  {
    name: 'general.menu.Tenant',
    icon: 'poll',
    permissions: ['tenant:list'],
    items: [
      {
        name: 'tenant.menu.Overview',
        route: 'tenant-management',
        permission: 'tenant:list',
      },
    ],
  },
  {
    name: 'general.menu.Pharmacy',
    icon: 'local_pharmacy',
    permissions: ['pharmacy:full'],
    items: [
      {
        name: 'pharmacy.menu.Overview',
        route: 'pharmacy-management',
        permission: 'pharmacy:full',
      },
    ],
  },
];

export { MAIN_MENU_ITEMS };

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Host,
  inject,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import {
  DEFAULT_INPUT_TYPE,
  DEFAULT_TEXT_AREA_COLUMNS,
  DEFAULT_TEXT_AREA_DISABLE_RESIZE,
  DEFAULT_TEXT_AREA_ROWS,
} from '../../form-controls/form-controls.const';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-text-area',
  templateUrl: './form-input-text-area-nd.component.html',
  styleUrls: ['./form-input-text-area-nd.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ValidationMessageComponent,
    CommonModule,
    TranslateModule,
  ],
  providers: [],
})
export class FormInputTextAreaNdComponent {
  @Input({
    required: true,
    transform: (value: string) => {
      return 'text-area-input-' + value;
    },
  })
  testId: string;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;

  @Input() type = DEFAULT_INPUT_TYPE;

  @Input() transformFns: ((value: string) => string)[] = [];

  //-TEXT-AREA
  @Input() cols = DEFAULT_TEXT_AREA_COLUMNS;
  @Input() rows = DEFAULT_TEXT_AREA_ROWS;
  @Input() disableResize = DEFAULT_TEXT_AREA_DISABLE_RESIZE;
  @Input() autoResize = false; //DEFAULT_AUTO_RESIZE;
  @Input() limit = '500'

  @Input() controlName: string;
  @Input() control: AbstractControl;

  readonly #destroyRef = inject(DestroyRef);


  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }

    if (this.control) {
      this.control.valueChanges
        .pipe(
          tap((value: string) => {
            let transformedValue = value;
            this.transformFns.forEach((fn) => {
              transformedValue = fn(transformedValue);
            });
            if (value !== transformedValue) {
              this.control?.setValue(transformedValue, { emitEvent: false });
            }
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
  }
}

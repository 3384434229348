@if (!template){
  <div [ngClass]="tooltipClasses">
    <div class="tooltip-content" style="margin: 0 2px; text-align: center">
      {{ content }}
    </div>
  </div>
} @else {
  <div [ngClass]="tooltipClasses">
    <div class="tooltip-content" style="margin: 0 2px;">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>
}


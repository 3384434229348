import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({ providedIn: 'root' })
export class CareProviderSpecificationService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];
  public careProviders = [];

  constructor(private masterDataService: MasterDataService) {
    this.getCareProviderSpecifications();
  }

  public getCareProviderSpecifications() {
    this.masterDataService
      .getMasterData('care-provider-specifications', true)
      .subscribe((data) => {
        this.careProviders = data.map((careProvider) => ({
          ...careProvider.value,
          label: `${careProvider.value.code} - ${careProvider.label}`,
        }))
        this.values = data;
        for (let item of this.values) {
          this.lookupValues[item.value.id] = `${item.value.code} - ${item.label}`;
        }
      });
  }
}


<div class="text-area-wrapper">
  <label>
    <mat-label>{{label | translate}}</mat-label>
  </label>

  <mat-form-field class="textarea-input-field" appearance="fill" [attr.disableResize]="disableResize ? '' :null" >
    <textarea
      [attr.testId]="testId"
      type="{{ type }}"
      matInput
      [formControl]="control"
      placeholder="{{placeholder|translate}}"
      [readonly]="disabled"
      tabindex="0"
      contenteditable="true"
      aria-multiline="true"
      [rows]="1"
      [maxLength]="limit"
      aria-required="true"
    ></textarea>
    <mat-error>
      @if(control?.errors){
      <app-validation-message
      [errors]="control.errors"
      ></app-validation-message>
      }
    </mat-error>
  </mat-form-field>
</div>